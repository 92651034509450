import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    component: () => import('../views/Monitoring.vue'),
    children: [
      {
        path: '/monitoring/info/:configId?',
        component: () => import('../components/MonitoringInfo.vue')
      },
      {
        path: '/monitoring/edit/:directionId/:configId?/:departmentId',
        component: () => import('../components/MonitoringEdit.vue')
      }
    ]
  },
  {
    path: '/standard',
    name: 'Standard',
    component: () => import('../views/Standard.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/StandardComponent.vue')
      },
      {
        path: '/standard/vnii/:configId?',
        component: () => import('../components/StandardVniiComponent.vue')
      },

      {
        path: '/standard/report/:id?',
        component: () => import('../components/StandardReport.vue')
      }
    ]
  },
  {
    path: '/passport',
    name: 'Passport',
    component: () => import('../views/Passport.vue'),
    children: [
      {
        path: '/passport/info/:configId?/:region?/:sectionIndex?',
        component: () => import('../components/PassportInfo.vue')
      }
    ]
  },
  {
    path: '/passportczn',
    name: 'PassportCZN',
    component: () => import('../views/PassportCZN.vue'),
    children: [
      {
        path: '/passportczn/info/:configId?/:departmentId?',
        component: () => import('../components/PassportCZNInfo.vue')
      },
      {
        path: '/passportczn/massEdit',
        component: () => import('../components/PassportCZNMassEdit.vue')
      }
    ]
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('../views/Event.vue'),
  },
  {
    path: '/eventRegions',
    name: 'EventRegions',
    component: () => import('../views/EventRegions.vue'),
  },
  // {
  //   path: '/rating',
  //   name: 'Rating',
  //   component: () => import('../views/Rating.vue')
  // },
  {
    path: '/infevents',
    name: 'Infevents',
    component: () => import('../views/Infevents.vue'),
    children: [
      {
        path: '/infevents/info/:configId?',
        component: () => import('../components/InfeventsInfo.vue')
      },
      {
        path: '/infevents/edit/:directionId/:configId?',
        component: () => import('../components/InfeventsEdit.vue')
      }
    ]
  },
  {
    path: '/situations',
    name: 'Situations',
    component: () => import('../views/Situations.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/SituationsRegister.vue')
      },
      {
        path: 'situationInfo/:operation?/:id?',
        component: () => import('../components/SituationInfo.vue')
      },
      {
        path: 'situationChoose',
        component: () => import('../components/SituationChoose.vue')
      },
    ]
  },
  {
    path: '/ankets',
    name: 'Ankets',
    component: () => import('../views/Ankets.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/AnketsRegister.vue')
      },
      {
        path: '/filtredAnkets/:configId?/:cznName?/:quarter?/:year?',
        component: () => import('../components/AnketsRegister.vue')
      },
      {
        path: 'anketInfo/:id',
        component: () => import('../components/AnketInfo.vue')
      }
    ]
  },
  {
    path: '/clientcentricity',
    name: 'ClientCentricity',
    component: () => import('../views/ClientCentricity.vue'),
    children: [
      {
        path: '/clientcentricity/checklist',
        component: () => import('../components/ClientCentricityCheckList')
      },
      {
        path: '/clientcentricity/anket',
        component: () => import('../components/ClientCentricityAnket')
      }
    ]
  },
  {
    path: '/unsubscribe',
    component: () => import('../components/config/Unsubscribe.vue')
  },
  {
    path: '/clientindex',
    name: 'ClientIndex',
    component: () => import('../views/ClientIndex.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/ClientIndex.vue')
      },
    ]
  },
  {
    path: '/config',
    name: 'Config',
    component: () => import('../views/Config.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/config/ConfigComponent.vue')
      },
      {
        path: 'monitoring',
        component: () => import('../components/config/MonitoringConfig.vue')
      },
      {
        path: 'monitoringConfigEdit/:id?',
        component: () => import('../components/config/MonitoringConfigEdit.vue')
      },
      {
        path: 'passport',
        component: () => import('../components/config/PassportConfig.vue')
      },
      {
        path: 'passportConfigEdit/:id?',
        component: () => import('../components/config/PassportConfigEdit.vue')
      },
      {
        path: 'passportczn',
        component: () => import('../components/config/PassportCZNConfig.vue')
      },
      {
        path: 'passportCZNConfigEdit/:id?',
        component: () => import('../components/config/PassportCZNConfigEdit.vue')
      },
      // {
      //   path: 'rating',
      //   component: () => import('../components/config/RatingConfig.vue')
      // },
      // {
      //   path: 'ratingConfigEdit',
      //   component: () => import('../components/config/RatingConfigEdit.vue')
      // },
      {
        path: 'situations',
        component: () => import('../components/config/SituationsConfig.vue')
      },
      {
        path: 'situationsConfigAdd/:id?',
        component: () => import('../components/config/SituationsConfigAdd.vue')
      },

      {
        path: 'ankets',
        component: () => import('../components/config/AnketsConfig.vue')
      },
      {
        path: 'anketsConfigEdit/:id?',
        component: () => import('../components/config/AnketsConfigEdit.vue')
      },
      {
        path: 'infevents',
        component: () => import('../components/config/InfeventsConfig.vue')
      },
      {
        path: 'infeventsConfigEdit/:id?',
        component: () => import('../components/config/InfeventsConfigEdit.vue')
      },
      {
        path: 'messages',
        component: () => import('../components/config/Messages.vue')
      },
      {
        path: 'notify',
        component: () => import('../components/config/Notify.vue')
      },
      {
        path: 'logs',
        component: () => import('../components/config/Logs.vue')
      },
      {
        path: 'users',
        component: () => import('../components/config/UsersManagement.vue')
      },
      {
        path: 'userEdit/:id?/:type?',
        component: () => import('../components/config/UserEdit.vue')
      },
      {
        path: 'sysinfo',
        component: () => import('../components/config/SysInfo.vue')
      },
      {
        path: 'notifConfig',
        component: () => import('../components/config/NotifConfig.vue')
      },
      {
        path: 'event',
        component: () => import('../components/config/EventConfig.vue')
      },
      {
        path: 'eventConfigEdit/:id?',
        component: () => import('../components/config/EventConfigEdit.vue')
      },
      {
        path: '/event/info/:configId?',
        component: () => import('../components/EventInfoComponent.vue')
      },
      {
        path: '/eventRegions/info/:configId?',
        component: () => import('../components/EventRegionsInfoComponent.vue')
      },
      {
        path: 'standardMonitoring',
        component: () => import('../components/config/StandardMonitoringConfig.vue')
      },
      {
        path: 'standardMonitoringEdit/:id?',
        component: () => import('../components/config/StandardMonitoringConfigEdit.vue')
      },
      {
        path: 'clientCentricity',
        component: () => import('../components/config/ClientCentricityConfig.vue')
      },
      {
        path: 'clientCentricityConfigEdit/:id?',
        component: () => import('../components/config/ClientCentricityConfigEdit.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
