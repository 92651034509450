import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Keycloak from 'keycloak-js'
import api from './modules/api'
import VueHtml2Canvas from "vue-html2canvas/src";

Vue.use(VueHtml2Canvas);

Vue.prototype.$backendUrl = 'https://app.new.czn-office.ru'
api.backendUrl = 'https://app.new.czn-office.ru'

// Vue.prototype.$backendUrl = 'http://localhost:8082'
// api.backendUrl = 'http://localhost:8082'

// Vue.prototype.$backendUrl = 'https://app.test.czn-office.ru'
// api.backendUrl = 'https://app.test.czn-office.ru'

// Vue.prototype.$backendUrl = 'https://app.testczn.nekadid.ru'
// api.backendUrl = 'https://app.testczn.nekadid.ru'

Vue.config.productionTip = true




//Прод

let initOptions = {
  url: 'https://auth.czn-office.ru/',
  realm: 'cznoffice',
  clientId: 'frontend',
  onLoad: 'login-required',
}

// Local
// let initOptions = {
//   url: 'https://auth.test.czn-office.ru/',
//   realm: 'cznoffice',
//   clientId: 'frontend',
//   onLoad: 'login-required',
// }

//Nekadid

// let initOptions = {
//   url: 'https://auth.nekadid.ru/',
//   realm: 'cznoffice',
//   clientId: 'frontend',
//   onLoad: 'login-required',
// }

let keycloak = Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak
Vue.prototype.$vcotUser = false

function updateHeaders () {
  const authHeaders = new Headers()
  authHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  const postHeaders = new Headers()
  postHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  //postHeaders.append('Accept', 'application/json')
  postHeaders.append('Content-Type', 'application/json')
  const postFormDataHeaders = new Headers()
  postFormDataHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  Vue.prototype.$authHeaders = authHeaders
  Vue.prototype.$postHeaders = postHeaders
  Vue.prototype.$postFormDataHeaders = postFormDataHeaders
}

Vue.prototype.$setBehalfUser = function(user){
  Vue.prototype.$behalfUser = user
}

Vue.prototype.$getUserByName = function(username){
  if(this.$user.username === username)
    return this.$user
  for(let u of Vue.prototype.$cznDepartmentsList){
    if(u.username === username){
      return u
    }
  }
}

Vue.prototype.$getDepartmentById = async function(id){
  let req = await Vue.prototype.$getApi("/department/getById", {id: id})
  if (req.ok) {
    return req.payload
  } else {
    console.log("error")
  }
}

keycloak.init({ onLoad: initOptions.onLoad }).then(async (auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    api.token = keycloak.token
    updateHeaders()
    // чтобы keycloak отдавал роли, нужно для клиента настроить mappers
    let role,
        name = keycloak.idTokenParsed.name,
        username = keycloak.idTokenParsed.preferred_username,


        /*
        * TODO Убрать перед релизом!!!
        * */

        subroles=keycloak.realmAccess.roles
        // console.log ('keycloak', keycloak)
    if (keycloak.realmAccess.roles.includes('ROLE_ADMIN')) {
      role = 'ROLE_ADMIN'
    } else if (keycloak.realmAccess.roles.includes('ROLE_VNII')) {
      role = 'ROLE_VNII'
      if(username.includes("@vcot.info")){
        Vue.prototype.$vcotUser = true
      }
    } else if (keycloak.realmAccess.roles.includes('ROLE_CZN_MANAGER')) {
      role = 'ROLE_CZN_MANAGER'
    } else if (keycloak.realmAccess.roles.includes('ROLE_CZN_TERRITORIAL')) {
      role = 'ROLE_CZN_TERRITORIAL'
    } else if (keycloak.realmAccess.roles.includes('ROLE_REGIONAL_AGENCY')) {
      role = 'ROLE_REGIONAL_AGENCY'
    } else {
      alert('Ошибка авторизации: неизвестная роль')
      return
    }
    // console.log('role', role)
    // subroles=keycloak.idTokenParsed.roles
    // if (keycloak.idTokenParsed.roles.includes('ROLE_ADMIN')) {
    //   role = 'ROLE_ADMIN'
    // } else if (keycloak.idTokenParsed.roles.includes('ROLE_VNII')) {
    //   role = 'ROLE_VNII'
    //   if(username.includes("@vcot.info")){
    //     Vue.prototype.$vcotUser = true
    //   }
    // } else if (keycloak.idTokenParsed.roles.includes('ROLE_CZN')) {
    //   role = 'ROLE_CZN'
    // } else {
    //   console.log(keycloak.idTokenParsed)
    //   alert('Ошибка авторизации: неизвестная роль')
    //   return
    // }


    /*
    * TODO Обновить для новой ролевой модели
    * */

    // if (role === 'ROLE_CZN_MANAGER' || role === 'ROLE_CZN_TERRITORIAL') {
    //   let req = await Vue.prototype.$getApi("/getCznUserInfo", {username: username});
    //   if(req.ok){
    //     name = req.payload.name
    //     region = req.payload.region
    //     Vue.prototype.$setBehalfUser(username)
    //   } else{
    //     alert("Ошибка получения данных о пользователе")
    //     return
    //   }
    // }
    //
    // if(role === 'ROLE_ADMIN' || role === 'ROLE_VNII'){
    //   let req = await Vue.prototype.$getApi("/department/getList");
    //   if(req.ok){
    //     Vue.prototype.$cznDepartmentsList = req.payload
    //     for(let u of Vue.prototype.$cznDepartmentsList) {
    //       if(u.region == null)
    //       // console.log(u)
    //       u.fullName = u.name
    //     }
    //     Vue.prototype.$setBehalfUser(Vue.prototype.$cznDepartmentsList[0].name)
    //     console.log('Vue.prototype.$behalfUser', Vue.prototype.$behalfUser)
    //   } else{
    //     alert("Ошибка получения данных")
    //     return
    //   }
    // }

      let reqDepartments = await Vue.prototype.$getApi("/department/getList");
      if(reqDepartments.ok) {
        Vue.prototype.$cznDepartmentsList = reqDepartments.payload;
      } else{
        alert("Ошибка получения данных")
        return
      }

      //Сортировка департаментов по регионам
      let map = new Map(),
          departmentsByRegion;
      for (let dep of Vue.prototype.$cznDepartmentsList) {
        if (!map.has(dep.region.code)) {
          map.set(dep.region.code, {
            name: dep.region.name,
            code: dep.region.code,
            year: dep.region.year,
            netType: dep.region.netType,
            departments: [dep]
          })
        } else {
          map.get(dep.region.code).departments.push(dep)
        }
      }
      departmentsByRegion = Array.from(map.values());
      // departmentsByRegion = Object.fromEntries(map.values());
      departmentsByRegion.sort((a, b) => (a.name > b.name ? 1 : -1));
      for (let dep of departmentsByRegion) {
        dep.departments.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
      Vue.prototype.$cznDepartmentsByRegionList = departmentsByRegion;
      // console.log('$cznDepartmentsByRegionList', Vue.prototype.$cznDepartmentsByRegionList)

    // if (role !== 'ROLE_CZN_TERRITORIAL') {
    //   let reqDepartments = await Vue.prototype.$getApi("/department/getList");
    //   if(reqDepartments.ok) {
    //     Vue.prototype.$cznDepartmentsList = reqDepartments.payload;
    //   } else{
    //     alert("Ошибка получения данных")
    //     return
    //   }
    //
    //   //Сортировка департаментов по регионам
    //   let tempDepartmentsList = JSON.parse(JSON.stringify(Vue.prototype.$cznDepartmentsList)),
    //       departmentsByRegion = [],
    //       counterForDel = [],
    //       indexWhile = 0;
    //   while (tempDepartmentsList.length !== 0) {
    //     counterForDel = [];
    //     departmentsByRegion.push({
    //       regionCode: tempDepartmentsList[0].region.code,
    //       regionName: tempDepartmentsList[0].region.name,
    //       departments: [],
    //     });
    //     for (let i = 0; i < tempDepartmentsList.length; i++) {
    //       if (tempDepartmentsList[i].region.code === departmentsByRegion[indexWhile].regionCode) {
    //         departmentsByRegion[indexWhile].departments.push(tempDepartmentsList[i]);
    //         counterForDel.push(i);
    //       }
    //     }
    //     counterForDel.reverse() //чтобы удаление элементов шло с конца
    //     for (let j = 0; j < counterForDel.length; j++) {
    //       tempDepartmentsList.splice(counterForDel[j], 1)
    //     }
    //     indexWhile++;
    //   }
    //
    //   departmentsByRegion.sort((a, b) => (a.regionName > b.regionName ? 1 : -1));
    //   Vue.prototype.$cznDepartmentsByRegionList = departmentsByRegion;
    //   // console.log("cznDepartmentsByRegionList", Vue.prototype.$cznDepartmentsByRegionList);
    // }

    //формирование атрибутов юзера
    Vue.prototype.$user = {
      username: username,
      name: name,
      role: role,
      subroles: subroles,
    }
    if (role === 'ROLE_CZN_MANAGER' || role === 'ROLE_CZN_TERRITORIAL') {
      Vue.prototype.$user.department = keycloak.idTokenParsed.department;
    }
    if (role === 'ROLE_CZN_MANAGER' || role === 'ROLE_CZN_TERRITORIAL' || role === 'ROLE_REGIONAL_AGENCY') {
      Vue.prototype.$user.region = {
        code: keycloak.idTokenParsed.region_code,
        name: keycloak.idTokenParsed.region_name,
        netType: keycloak.idTokenParsed.region_net_type
      };
    }
    // console.log ('$user', Vue.prototype.$user)


    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      render: h => h(App, {props: {keycloak: keycloak}}),
      vuetify,
      router
    })
  }

  // Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        api.token = keycloak.token;
        updateHeaders()
      } else {
        // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
      }
    }).catch(() => {
      console.log('Failed to refresh token')
    })
  }, 6000)
}).catch((e) => {
  console.log('Authentication Failed: ' + e)
})

// import YmapPlugin from 'vue-yandex-maps'
// const settings = {
//   apiKey: '90a80d24-0d3c-4471-b98d-2acf543bc522',
//   lang: 'ru_RU',
//   coordorder: 'latlong',
//   enterprise: false,
//   version: '2.1',
// }
//
// Vue.use(YmapPlugin, settings)


function clean (obj) {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

Vue.prototype.$getApi = async function (path, querySpec) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  if (querySpec != null) {
    url.search = new URLSearchParams(clean(querySpec)).toString()
  }
  try {
    let req = await fetch(url.toString(), {method: 'GET', headers: this.$authHeaders})
    if (req.ok) {
      return {ok: true, payload: await tryGetJson(req)}
    } else {
      // console.log(req)
      return {ok: false, payload: null, error: req.err}
    }
  } catch (e) {
    console.log(e)
    return {ok: false, error: e}
  }
}

Vue.prototype.$postApi = async function (path, obj) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  try {
    let req = await fetch(url.toString(), {method: 'POST', headers: this.$postHeaders, body: JSON.stringify(obj)})
    // console.log(req)
    if (req.ok) {
      return {ok: true, payload: await tryGetJson(req)}
    } else {
      return {ok: false, payload: null, error: req.err ? req.err : await req.text()}
    }
  } catch (e) {
    console.log(e)
    return {ok: false, error: e}
  }
}

Vue.prototype.$postFormDataApi = async function (path, formData) {
  if (path[0] !== '/') {
    path = '/' + path
  }

  //На вход удобно также получать не форм дата, а объект
  if(!(formData instanceof FormData)){
    let correctFormData = new FormData();

    for (let key in formData) {
      correctFormData.append(key, formData[key]);
    }
    formData = correctFormData
  }

  const url = new URL(this.$backendUrl + path)
  let req = await fetch(url.toString(), {method: 'POST', headers: this.$postFormDataHeaders, body: formData})
  if (req.ok) {
    return {ok: true, payload: await tryGetJson(req)}
  } else {
    // console.log(req)
    return {ok: false, payload: null}
  }
}

async function tryGetJson(req){
  let result = null
  try{
    result = await req.json()
  } catch (e){
    result = null
  }
  return result
}


async function downloadFie (fileName, url, method, headers, body) {
  let req = await fetch(url.toString(), {method: method, headers: headers, body: body})
  if (!req.ok) {
    alert('Ошибка скачивания файла!')
    console.log(req)
  }
  let urln = URL.createObjectURL(await req.blob())
  const a = document.createElement('a')
  a.download = fileName
  a.style.display = 'none'
  a.href = urln
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(urln)
}

Vue.prototype.$downloadApi = async function(fileName, path, querySpec, isJsonBody) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  if(isJsonBody === true) {
    await downloadFie(fileName, url.toString(), "POST", this.$postHeaders, JSON.stringify(querySpec))
  } else {
    if (querySpec != null) {
      url.search = new URLSearchParams(clean(querySpec)).toString()
    }
    await downloadFie(fileName, url, "GET", this.$authHeaders)
  }
}
