const api = {
    backendUrl: null,
    token: null,

    async get(path, querySpec, mode = "json") {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'GET', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`
                } : {}
            })
            if (req.ok) {
                let payload
                if (mode === "json") {
                    payload = await this.tryGetJson(req)
                } else if (mode === "blob") {
                    payload = await req.blob()
                }
                return {ok: true, payload: payload}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async download(path, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })
            // console.log(req)
            if (req.ok) {
                return { ok: true, payload: await req.blob() }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async postDownloadFile(path, obj, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            // console.log(req)
            if (req.ok) {
                return { ok: true, payload: await req.blob() }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    //метод удаления файла с передачей параметров в виде запроса
    async delete(path, querySpec, body) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req;
            if (body) {
                req = await fetch(url.toString(), {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    },
                    body: body
                })
            } else {
                req = await fetch(url.toString(), {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })
            }
            // console.log(req)
            if (req.ok) {
                return { ok: true }
            } else {
                console.log(req)
                return { ok: false }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    //метод записи/обновления файла
    async postFile(path, querySpec, obj, method) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req = await fetch(url.toString(), {
                method: method,
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    // 'Content-Type': 'multipart/form-data'
                },
                body: obj
            })
            if (req.ok) {
                return { ok: true, payload: await this.tryGetJson(req)}
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async postJson(path, obj, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path);
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (req.ok) {
                return { ok: true, payload: await this.tryGetJson(req) }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async put(path, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString();
            // console.log(url)
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'PUT', headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })
            if (req.ok) {
                return { ok: true, payload: await this.tryGetJson(req) }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async tryGetJson(req) {
        let result = null
        try {
            result = await req.json()
        } catch (e) {
            result = null
        }
        return result
    },

    clean (obj) {
        for (let propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName]
          }
        }
        return obj
      }
}

export default api